import qs from 'qs';
import axios from 'axios';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Column } from 'react-table';

import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';
import { Table, FormatDate, getFormattedDate } from '@itm/shared-frontend/lib/components';
import { Converter, ServerErrorAdapter, RequestSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import { getUserAuditListSearch } from 'src/api/eArchive/audit';

import { userAuditTypeOptions, userAuditStatusOptions } from 'src/utils/constants';
import {
  UserAuditResponse,
  TableChangeParams,
  UserAuditType,
  UserAuditStatus,
  SelectOption,
  ServerError,
  ServerFormErrors,
} from 'src/types';

import styles from './UserAudit.module.scss';

const filterKeyPriority: Record<string, number> = [
  'reportname',
  'schemename',
  'reportid',
  'schemeid',
  'policynumbers',
  'startdate',
  'enddate',
  'take',
  'skip',
].reduce((acc, word, index) => ({ ...acc, [word]: index + 1 }), {});

const paramsKeyForced: Record<string, string> = {
  dob: 'DOB',
};

const userAuditTypeOptionsWithEmpty = [{ label: '', value: '' }, ...userAuditTypeOptions];
const userAuditStatusOptionsWithEmpty = [{ label: '', value: '' }, ...userAuditStatusOptions];

function UserAudit() {
  const prevTableChangeParamsRef = useRef<TableChangeParams<UserAuditResponse>>();
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState<UserAuditResponse[]>([]);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);
  const columns = useMemo<Column<UserAuditResponse>[]>(
    () => [
      { Header: 'User Name', accessor: 'userName', headerClassName: 'is-narrow', className: styles.Cell },
      { Header: 'Action Type', accessor: 'type', Cell: ({ value }) => <>{Converter.splitUpperCase(value)}</> },
      {
        Header: 'Report/search filter',
        accessor: 'params',
        disableSortBy: true,
        Cell: ({ value }) => {
          if (!value) return <>-</>;
          let parsedValue: Record<string, string | string[]> | undefined;
          try {
            parsedValue = JSON.parse(value);
          } catch (e) {}
          if (!parsedValue) {
            try {
              parsedValue = qs.parse(value) as any;
            } catch (e) {}
          }
          if (!parsedValue) return <>-</>;
          return (
            <dl className="is-horizontal is-term py-2">
              {Object.entries(parsedValue)
                .sort(
                  ([keyA], [keyB]) =>
                    (filterKeyPriority[keyA.toLocaleLowerCase()] || 99) -
                    (filterKeyPriority[keyB.toLocaleLowerCase()] || 99),
                )
                .map(([key, value]) => {
                  const keyLowerCase = key.toLowerCase();
                  const keyString =
                    paramsKeyForced[keyLowerCase] ||
                    Converter.splitUpperCase(key.replace(/^\w/, (w) => w.toLocaleUpperCase()));

                  let valueString;
                  if (Array.isArray(value)) {
                    valueString = value.join(', ');
                  } else if (keyLowerCase === 'startdate' || keyLowerCase === 'enddate') {
                    valueString = getFormattedDate(value, 'dd/MM/yyyy');
                  } else {
                    valueString = value;
                  }

                  if (!valueString) {
                    valueString = '-';
                  }

                  return [<dt key={`dt-${key}`}>{keyString}</dt>, <dd key={`dd-${key}`}>{valueString}</dd>];
                })}
            </dl>
          );
        },
      },
      { Header: 'Status', accessor: 'status', Cell: ({ value }) => <>{Converter.splitUpperCase(value)}</> },
      {
        Header: 'Datetime',
        accessor: 'dateTime',
        headerClassName: 'is-narrow',
        className: 'is-nowrap',
        Cell: ({ value }) => <FormatDate date={value} formatValue="dd/MM/yyyy HH:mm:ss" />,
      },
    ],
    [],
  );

  const [type, setType] = useState<UserAuditType | ''>('');
  const [status, setStatus] = useState<UserAuditStatus | ''>('');
  const CustomGlobalFilter = useMemo(
    () => (globalFilterElement: JSX.Element) => {
      const typeOption = userAuditTypeOptionsWithEmpty.find((option) => option.value === type) || null;
      const statusOption = userAuditStatusOptionsWithEmpty.find((option) => option.value === status) || null;

      const typeChangeHandler = (newOption: SingleValue<SelectOption>) => {
        const selectedType = newOption ? (newOption.value as UserAuditType) : '';
        setType(selectedType);
      };
      const statusChangeHandler = (newOption: SingleValue<SelectOption>) => {
        const selectedStatus = newOption ? (newOption.value as UserAuditStatus) : '';
        setStatus(selectedStatus);
      };
      return (
        <div className="columns is-multiline">
          <div className="column">
            <div className="field">
              <label>
                <div className="label">&nbsp;</div>
                <div className="is-flex">{globalFilterElement}</div>
              </label>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label>
                <div className="label">Action Type</div>
                <div className="control">
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={typeOption}
                    options={userAuditTypeOptionsWithEmpty}
                    placeholder="Action Type"
                    onChange={typeChangeHandler}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label>
                <div className="label">Status</div>
                <div className="control">
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={statusOption}
                    options={userAuditStatusOptionsWithEmpty}
                    placeholder="Status"
                    onChange={statusChangeHandler}
                    isClearable={false}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      );
    },
    [type, status],
  );

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  const tableChangeHandler = useCallback(
    async (tableChangeParams: TableChangeParams<UserAuditResponse>) => {
      cleanup();
      const abortController = new AbortController();
      abortControllerSet.add(abortController);
      setServerErrorMessages([]);
      setIsLoading(true);
      prevTableChangeParamsRef.current = tableChangeParams;
      try {
        const params = { ...new RequestSearchParamsAdapter(tableChangeParams), type, status };
        const config = { signal: abortController.signal };
        const res = await getUserAuditListSearch(params, config);
        const { totalCount, auditRecords } = res.data;
        setPageCount(Math.ceil(totalCount / tableChangeParams.pageSize));
        setData(auditRecords);
      } catch (e) {
        if (e instanceof axios.Cancel) return;
        const { formErrors } = new ServerErrorAdapter(e as ServerError);
        setServerErrorMessages(formErrors);
      }
      setIsLoading(false);
      abortControllerSet.delete(abortController);
    },
    [cleanup, abortControllerSet, type, status],
  );

  useEffect(() => {
    if (prevTableChangeParamsRef.current) {
      tableChangeHandler({ ...prevTableChangeParamsRef.current, pageIndex: 0 });
    }
  }, [type, status, tableChangeHandler]);

  useEffect(() => () => cleanup(), [cleanup]);

  return (
    <section className="columns">
      <div className="column is-12">
        <Table
          data={data}
          columns={columns}
          pageCount={pageCount}
          isLoading={isLoading}
          onParamsChange={tableChangeHandler}
          CustomGlobalFilter={CustomGlobalFilter}
          disableFilters
        />
        <ServerErrorMessages messages={serverErrorMessages} />
      </div>
    </section>
  );
}

export default UserAudit;
