import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { getCompanyListAll } from 'src/api/eArchive/company';
import { CompanyResponse } from 'src/types';

type CompanyState = {
  isLoading: boolean;
  allCompanies: CompanyResponse[];
  selectedCompanyId: string;
};

export const fetchCompaniesAllAction = createAsyncThunk('company/fetchCompanyListAll', async () => {
  const res = await getCompanyListAll();
  return res.data;
});

const initialState: CompanyState = {
  isLoading: false,
  allCompanies: [],
  selectedCompanyId: '',
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    selectCompany(state: CompanyState, action: PayloadAction<string>) {
      state.selectedCompanyId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // SuperAdmin
    builder.addCase(fetchCompaniesAllAction.pending, (state: CompanyState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompaniesAllAction.fulfilled, (state: CompanyState, action) => {
      state.isLoading = false;
      state.allCompanies = action.payload;
    });
    builder.addCase(fetchCompaniesAllAction.rejected, (state: CompanyState) => {
      state.isLoading = false;
    });

    // Cleanup
    builder.addCase(resetStore, (state: CompanyState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { selectCompany } = companySlice.actions;

export default companySlice.reducer;
