import { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormErrors } from '@itm/shared-frontend/lib/hooks';

import { useSelector, useDispatch } from 'src/store';
import { setProductDescription } from 'src/store/slices/productSlice';
import { productInfoSelector } from 'src/store/selectors/productSelector';

import { checkFormHasChanges } from '@itm/shared-frontend/lib/utils';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import { updateProductDescription } from 'src/api/eArchive/product';

import { ServerError } from 'src/types';

type FormData = {
  description: string;
};

function ProductInfo() {
  const dispatch = useDispatch();
  const { id, description, version } = useSelector(productInfoSelector);
  const [isUpdated, setIsUpdated] = useState(false);

  const formSchema = useMemo(
    () =>
      object().shape({
        description: string().label('Description').trim().required(),
      }),
    [],
  );

  const { register, control, handleSubmit, formState, setValue, setError, clearErrors } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { serverErrorMessages, handleErrors } = useFormErrors<FormData>(setError, clearErrors);

  const onSubmit = handleSubmit(async (data) => {
    setIsUpdated(false);
    if (!checkFormHasChanges(data, { description })) {
      const error = new Error('You have to change text field to update.');
      handleErrors(error as ServerError);
      return;
    }

    try {
      handleErrors();
      await updateProductDescription(data.description);
      dispatch(setProductDescription(data));
      setIsUpdated(true);
    } catch (e) {
      handleErrors(e as ServerError);
      setIsUpdated(false);
    }
  });

  useEffect(() => {
    if (description) {
      setValue('description', description);
    }
  }, [description, setValue]);

  return (
    <section className="columns is-multiline">
      <div className="column is-12">
        <h3 className="is-size-2 mb-3">Information</h3>

        <p className="is-size-3 mb-3">
          {id ? (
            <>
              <b>Product Id: </b> {id}
            </>
          ) : (
            'There is no information'
          )}
        </p>
        {version && (
          <p className="is-size-3">
            <b>Version: </b> {version}
          </p>
        )}
      </div>
      <div className="column is-6-desktop is-8-tablet is-12-mobile">
        <h3 className="is-size-2 mb-4">Landing Page Title</h3>
        <form name="updateProductDescription" onSubmit={onSubmit} noValidate>
          <Field
            field="textarea"
            type="text"
            autoComplete="off"
            register={register('description')}
            control={control}
            formSchema={formSchema}
            errors={formState.errors}
          />
          <LoadingButton
            className="button is-interact is-pulled-right"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Save
          </LoadingButton>
          {isUpdated && <p className="has-text-success">The page title has been successfully updated.</p>}
        </form>
        <ServerErrorMessages messages={serverErrorMessages} />
      </div>
    </section>
  );
}

export default ProductInfo;
