import { eArchiveApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { Report, DownloadReportDto } from 'src/types';

export const getReport = (data: DownloadReportDto, config: AxiosRequestConfig = {}) =>
  eArchiveApi.post<Blob>('/api/v1/reports/download', data, { ...config, responseType: 'blob' });

export const getReportNameListAll = (config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<Report[]>('/api/v1/reports/names', config);

export const getProductTypeListBySchemeId = (schemeId: string, config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<string[]>(`/api/v1/reports/productTypes/${schemeId}`, config);

type GetMainClassListParams = {
  schemeId: string;
  reportId: string;
};
export const getMainClassList = (params: GetMainClassListParams, config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<string[]>('/api/v1/reports/mainClasses', { ...config, params });
