import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import ITMLogoBlueSVG from '@itm/shared-frontend/lib/assets/images/itm-logo-blue.svg';

export const tenantSliceSelector = (state: RootState) => state.tenant;

export const tenantBrandingSelector = createSelector(tenantSliceSelector, (state) => state.branding);

export const tenantBrandingNameSelector = createSelector(tenantBrandingSelector, (state) =>
  state && state.name ? state.name : 'ITM data Hub',
);
export const tenantBrandingLogoAltSelector = createSelector(tenantBrandingNameSelector, (state) => `${state} logo`);
export const tenantBrandingLogoUrlSelector = createSelector(tenantBrandingSelector, (state) =>
  state && state.logoUrl ? state.logoUrl : ITMLogoBlueSVG,
);
