import { eArchiveApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { RequestSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';
import { GetAuditResponse, UserAuditRecordsResponse, UserAuditType, UserAuditStatus } from 'src/types';

export const getAuditListSearch = (params: RequestSearchParamsAdapter = {}, config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<GetAuditResponse>('/api/v1/audit/search', { ...config, params });

type GetUserAuditListSearchParams = RequestSearchParamsAdapter & {
  type?: UserAuditType | '';
  status?: UserAuditStatus | '';
};
export const getUserAuditListSearch = (params: GetUserAuditListSearchParams, config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<UserAuditRecordsResponse>('/api/v1/audit/user/search', { ...config, params });
