import { createRoot } from 'react-dom/client';
import App from './App';

import '@itm/shared-frontend/lib/style.css';
import 'regenerator-runtime/runtime';
import 'src/config/yup.config';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
