import qs from 'qs';
import { useState, useMemo, useEffect, useCallback, PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';
import {
  selectedCompanyIdSelector,
  isLoadingCompanySelector,
  allCompanyOptionsSelector,
} from 'src/store/selectors/companySelector';
import { selectedSchemeIdSelector } from 'src/store/selectors/schemeSelector';
import { selectCompany } from 'src/store/slices/companySlice';
import { selectScheme, fetchSchemeListByCompanyAction } from 'src/store/slices/schemeSlice';

import { history } from 'src/router';

import { useIsMount } from '@itm/shared-frontend/lib/hooks';

export const preserveKeyList = ['companyId', 'schemeId'] as const;

type SearchParams = Partial<Record<(typeof preserveKeyList)[number], string>>;
function SyncFiltersWithURL(props: PropsWithChildren<object>) {
  const isMount = useIsMount();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isLoadingCompany = useSelector(isLoadingCompanySelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);
  const { search: locationSearch } = useLocation();
  const { companyId = '', schemeId = '' } = useMemo<SearchParams>(
    () => qs.parse(locationSearch.slice(1)),
    [locationSearch],
  );
  const [isLoading, setIsLoading] = useState(true);

  const fillSchemeOptions = useCallback(async () => {
    if (!selectedCompanyId) return;
    try {
      await dispatch(fetchSchemeListByCompanyAction(selectedCompanyId));
    } catch (e) {}
    setIsLoading(false);
  }, [selectedCompanyId, dispatch]);

  const syncCompanyId = useCallback(() => {
    if (companyId === selectedCompanyId) return;
    if (isMount) {
      dispatch(selectCompany(companyId));
    } else {
      const { pathname, search, state } = history.location;
      const query = search.slice(1);
      const searchParams = qs.parse(query);

      searchParams.companyId = selectedCompanyId;
      navigate({ pathname, search: qs.stringify(searchParams) }, { state, replace: true });
    }
  }, [companyId, selectedCompanyId, isMount, dispatch, navigate]);

  const syncSchemeId = useCallback(() => {
    if (schemeId === selectedSchemeId) return;
    if (isMount) {
      dispatch(selectScheme(schemeId));
    } else {
      const { pathname, search, state } = history.location;
      const query = search.slice(1);
      const searchParams = qs.parse(query);

      searchParams.schemeId = selectedSchemeId;
      navigate({ pathname, search: qs.stringify(searchParams) }, { state, replace: true });
    }
  }, [schemeId, selectedSchemeId, isMount, dispatch, navigate]);

  useEffect(() => {
    syncCompanyId();
  }, [syncCompanyId]);

  useEffect(() => {
    syncSchemeId();
  }, [syncSchemeId]);

  useEffect(() => {
    fillSchemeOptions();
  }, [fillSchemeOptions]);

  // Finish loading if there is no companies
  useEffect(() => {
    if (!isLoadingCompany && companyOptions.length === 0) {
      setIsLoading(false);
    }
  }, [isLoadingCompany, companyOptions]);

  return <>{!isLoading || !isLoggedIn ? props.children : null}</>;
}

export default SyncFiltersWithURL;
