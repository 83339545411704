import { useMemo, useRef, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { isLoggedInSelector, authRoleSelector } from 'src/store/selectors/authSelector';

import { Role } from 'src/types';

type Props = {
  meta: {
    auth?: boolean;
    role?: {
      allowList: Role[];
    };
  };
};

export function GuardedRoute({ meta, children }: PropsWithChildren<Props>) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userRole = useSelector(authRoleSelector);
  const hrefRef = useRef('');

  let redirectRoute = RoutePath.root;

  const passAuthGuard = useMemo(() => {
    if (!meta.auth) return true;
    return isLoggedIn;
  }, [meta, isLoggedIn]);

  const passRolesGuard = useMemo(() => {
    if (!meta.role) return true;
    return userRole && meta.role.allowList.includes(userRole);
  }, [meta, userRole]);

  const isRouteAllowed = useMemo(() => passAuthGuard && passRolesGuard, [passAuthGuard, passRolesGuard]);

  if (passAuthGuard) {
    if (!passRolesGuard) {
      redirectRoute = RoutePath.permissionDenied;
    }
  } else {
    redirectRoute = RoutePath.loginRedirect;
  }

  // Save full URL before logout
  if (isLoggedIn) {
    hrefRef.current = '';
  } else if (!hrefRef.current) {
    hrefRef.current = window.location.href;
  }

  return <>{isRouteAllowed ? children : <Navigate to={redirectRoute} state={{ href: hrefRef.current }} />}</>;
}
