import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Role } from 'src/types';

import { jwtRoleKey } from '@itm/shared-frontend/lib/utils';

export const authSliceSelector = (state: RootState) => state.auth;

export const authUserDataSelector = createSelector(authSliceSelector, (state) => state.identityUser);

export const authUserAttributesSelector = createSelector(
  authUserDataSelector,
  (userData) =>
    userData?.userAttributes?.reduce<Record<string, string | null>>(
      (acc, { name, value }) => (name ? { ...acc, [name]: value } : acc),
      {},
    ) || {},
);

export const authRoleSelector = createSelector(authUserAttributesSelector, (userAttributes): Role | null => {
  const rolesAttribute: string | null = userAttributes[jwtRoleKey] || null;
  if (!rolesAttribute) return null;
  const roles = rolesAttribute.split(/;\s?/).map((roleWithExtra: string) => {
    const index = roleWithExtra.indexOf(':');
    const role = index === -1 ? roleWithExtra : roleWithExtra.slice(0, index);
    return role.toLocaleLowerCase();
  });

  return Object.values(Role).find((role) => roles.includes(role.toLocaleLowerCase())) || null;
});

export const isLoggedInSelector = createSelector(authRoleSelector, (role) => Boolean(role));
