import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { AdminGetUserResponse } from '@itm/shared-frontend/lib/components/initial';

type AuthState = {
  identityUser: AdminGetUserResponse | null;
};

const initialState: AuthState = {
  identityUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIdentityUser(state: AuthState, action: PayloadAction<AdminGetUserResponse | null>) {
      state.identityUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Cleanup
    builder.addCase(resetStore, (state: AuthState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setIdentityUser } = authSlice.actions;

export default authSlice.reducer;
