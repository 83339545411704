import axios from 'axios';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { Column, useAsyncDebounce } from 'react-table';

import { useForm, useWatch } from 'react-hook-form';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormErrors } from '@itm/shared-frontend/lib/hooks';

import LogsFilter from './LogsFilter';
import { Table, FormatDate } from '@itm/shared-frontend/lib/components';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import { getLogsListSearch } from 'src/api/eArchive/logs';

import { LogResponse, GetLogListSearchParams, ServerError } from 'src/types';

import styles from '../userAudit/UserAudit.module.scss';

const formSchema = object().shape({
  search: string().label('Search').trim().optional(),
  take: string().label('Take').required(),
  logLevel: string().label('Log level'),
  startDateTime: string().label('Start Date'),
  endDateTime: string().label('End Date'),
});

const weekAgoDateInMilliseconds = Number(new Date()) - 1000 * 60 * 60 * 24 * 7;

function Logs() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LogResponse[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const { register, control, handleSubmit, formState, setError, clearErrors } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      take: '1000',
      logLevel: 'All',
      startDateTime: new Date(new Date(weekAgoDateInMilliseconds).toISOString().slice(0, 10)).toISOString(),
    },
  });

  const formData = useWatch({ control });
  const { serverErrorMessages, handleErrors } = useFormErrors(setError, clearErrors);

  const columns = useMemo<Column<LogResponse>[]>(
    () => [
      // { Header: 'Trace Id', accessor: 'traceId' },
      {
        Header: 'Datetime',
        accessor: 'timestamp',
        headerClassName: 'is-narrow',
        className: 'is-nowrap',
        Cell: ({ value }) => (value ? <FormatDate date={value} formatValue="dd/MM/yyyy HH:mm:ss" /> : null),
      },
      { Header: 'Level', accessor: 'level', className: styles.Cell },
      { Header: 'Message', accessor: 'message', className: styles.Cell },
      { Header: 'Exception', accessor: 'exception', className: styles.Cell },
    ],
    [],
  );

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  const fillLogList = handleSubmit(async (data) => {
    cleanup();
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setIsLoading(true);
    try {
      handleErrors();
      const params = Object.entries(data).reduce((acc, [paramName, value]) => {
        if (value) {
          let paramValue = value;
          if (paramName === 'endDateTime') {
            const valueDate = new Date(value);
            paramValue = new Date(
              Date.UTC(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate(), 23, 59, 59, 999),
            ).toISOString();
          }
          return { ...acc, [paramName]: paramValue };
        }
        return acc;
      }, {} as GetLogListSearchParams);
      const config = { signal: abortController.signal };
      const res = await getLogsListSearch(params, config);
      const { totalCount, logRecords } = res.data;
      setTotalCount(totalCount);
      setData(logRecords);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      handleErrors(e as ServerError);
    }
    setIsLoading(false);
    abortControllerSet.delete(abortController);
  });

  const fillLogListDebounced = useAsyncDebounce(() => fillLogList(), 300);

  useEffect(() => {
    fillLogListDebounced();
  }, [fillLogListDebounced, formData]);

  useEffect(() => () => cleanup(), [cleanup]);

  return (
    <section className="columns is-multiline">
      <div className="column is-12">
        <LogsFilter register={register} control={control} formSchema={formSchema} errors={formState.errors} />
      </div>
      <p className="column is-12">
        Total Count: <b>{totalCount}</b>
      </p>
      <div className="column is-12">
        <Table data={data} columns={columns} isLoading={isLoading} disableFilters disableGlobalFilter />
        <ServerErrorMessages messages={serverErrorMessages} />
      </div>
    </section>
  );
}

export default Logs;
