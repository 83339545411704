import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TenantBranding = {
  logoUrl: string | null;
  name: string | null;
  termsAndConditions: string | null;
};

type TenantBrandingState = {
  branding: TenantBranding | null;
};

const initialState: TenantBrandingState = {
  branding: null,
};

const TenantBrandingSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setTenantBranding(state: TenantBrandingState, action: PayloadAction<TenantBranding | null>) {
      state.branding = action.payload;
    },
  },
});

export const { setTenantBranding } = TenantBrandingSlice.actions;

export default TenantBrandingSlice.reducer;
