import { UseFormRegister } from 'react-hook-form';

import { Field, FieldErrorMessage } from '@itm/shared-frontend/lib/components/forms';
import { MagnifyingGlassIcon } from '@itm/shared-frontend/lib/components/icons/';

import { logLevelOptions } from 'src/utils/constants';

import { SelectOption } from 'src/types';

type AdministratorDetailsFieldsProps = {
  register: UseFormRegister<any>;
  control: any;
  formSchema: any;
  errors?: any;
};
const takeOptions: SelectOption[] = [
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '500', value: '500' },
  { label: '1000', value: '1000' },
  { label: '5000', value: '5000' },
  { label: '10000', value: '10000' },
];

function LogsFilter({ register, control, formSchema, errors }: AdministratorDetailsFieldsProps) {
  return (
    <form name="logFiltersForm" noValidate className="columns is-multiline is-align-items-flex-end">
      <div className="column is-2">
        <div className="field">
          <label>
            <div className="label">&nbsp;</div>
            <div className="control has-icons-right">
              <input className="input" type="search" placeholder="Search" {...register('search')} />
              <span className="icon is-small is-right">
                <MagnifyingGlassIcon />
              </span>
            </div>
          </label>
        </div>
        <FieldErrorMessage name="search" errors={errors} />
      </div>
      <div className="column is-2">
        <Field
          label="Level"
          placeholder="Select log level"
          inputClassName="is-fullwidth"
          field="dropdown"
          autoComplete="off"
          options={logLevelOptions}
          register={register('logLevel')}
          control={control}
          formSchema={formSchema}
          errors={errors}
        />
      </div>
      <div className="column is-2">
        <Field
          label="Start Date"
          field="datepicker"
          autoComplete="off"
          register={register('startDateTime')}
          control={control}
          formSchema={formSchema}
          errors={errors}
        />
      </div>
      <div className="column is-2">
        <Field
          label="End Date"
          field="datepicker"
          autoComplete="off"
          register={register('endDateTime')}
          control={control}
          formSchema={formSchema}
          errors={errors}
        />
      </div>
      <div className="column is-2 is-offset-2">
        <Field
          inputClassName="is-fullwidth"
          label="Number of results requested"
          field="dropdown"
          placeholder=""
          autoComplete="off"
          register={register('take')}
          options={takeOptions}
          control={control}
          formSchema={formSchema}
          errors={errors}
        />
      </div>
    </form>
  );
}

export default LogsFilter;
