import classNames from 'classnames';

import { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { GuardedRoute } from 'src/router';

import { useSelector } from 'src/store';
import { authRoleSelector } from 'src/store/selectors/authSelector';
import { productInfoSelector } from 'src/store/selectors/productSelector';

import { TabLayout } from '@itm/shared-frontend/lib/components/layout';
import type { TabOption } from '@itm/shared-frontend/lib/components/layout/TabLayout';

import PolicySearchPage from './policy/PolicySearch';
import PolicyDetailsPage from './policy/PolicyDetails';

import ReportsPage from './reports/Reports';

import UserAuditPage from './userAudit/UserAudit';

// import AuditPage from './audit/Audit';

import LogsPage from './logs/Logs';

import ProductInfoPage from './productInfo/ProductInfo';

import { Role } from 'src/types';

import styles from './index.module.scss';

function Landing() {
  const userRole = useSelector(authRoleSelector);
  const { description } = useSelector(productInfoSelector);

  const tabLayoutOptions = useMemo<TabOption[]>(
    () =>
      [
        {
          path: 'policy/search',
          tabTitle: 'Search',
          routeComponent: PolicySearchPage,
          meta: {
            auth: true,
            role: {
              allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager],
            },
          },
        },
        {
          path: 'reports',
          tabTitle: 'Reports',
          routeComponent: ReportsPage,
          meta: {
            auth: true,
            role: {
              allowList: [Role.SuperAdmin, Role.Support, Role.ClientAdmin, Role.Manager],
            },
          },
        },
        {
          path: 'user-audit',
          tabTitle: 'User Audit',
          routeComponent: UserAuditPage,
          meta: {
            auth: true,
            role: {
              allowList: [Role.SuperAdmin, Role.Support],
            },
          },
        },
        // {
        //   path: 'audit',
        //   tabTitle: 'Audit',
        //   routeComponent: AuditPage,
        //   meta: {
        //     auth: true,
        //     role: {
        //       allowList: [Role.SuperAdmin],
        //     },
        //   },
        // },
        {
          path: 'logs',
          tabTitle: 'Logs',
          routeComponent: LogsPage,
          meta: {
            auth: true,
            role: {
              allowList: [Role.SuperAdmin, Role.Support],
            },
          },
        },
        {
          path: 'info',
          tabTitle: 'Info',
          routeComponent: ProductInfoPage,
          meta: {
            auth: true,
            role: {
              allowList: [Role.SuperAdmin, Role.Support],
            },
          },
        },
      ].filter(({ meta }) => (meta.role ? userRole && meta.role.allowList.includes(userRole) : true)),
    [userRole],
  );

  return (
    <>
      <div className="px-3">
        <div className="container">
          <div className="container px-2 is-flex is-flex-grow-0 is-flex-direction-column is-align-items-center">
            <p className={classNames(styles.Description, 'pt-5 mb-6 is-size-5 has-text-centered')}>{description}</p>
          </div>
        </div>
      </div>

      <div className="px-3">
        <div className="container">
          <TabLayout tabLayoutOptions={tabLayoutOptions}>
            <Routes>
              <Route index element={<Navigate to={tabLayoutOptions[0].path} replace={true} />} />

              {tabLayoutOptions.map(({ path, routeComponent: Component, meta }) => (
                <Route
                  path={path}
                  element={<GuardedRoute meta={meta}>{Component && <Component />}</GuardedRoute>}
                  key={path}
                />
              ))}

              <Route path="*" element={<Navigate to="./" />} />
            </Routes>
          </TabLayout>
        </div>
      </div>
    </>
  );
}

function LandingRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="console" replace={true} />} />

      {/* Landing */}
      <Route
        path="console/*"
        element={
          <GuardedRoute meta={{ auth: true }}>
            <Landing />
          </GuardedRoute>
        }
      />
      {/* RoutePath.landingPolicyDetails */}
      <Route
        path="policy/:policyNumber/details"
        element={
          <GuardedRoute meta={{ auth: true }}>
            <PolicyDetailsPage />
          </GuardedRoute>
        }
      />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default LandingRoutes;
