import { eArchiveApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { RequestSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';
import { DetailsPolicyIndexResponse, SearchPolicyIndexResponse } from 'src/types';

export const getPolicyByPolicyNumber = (policyNumber: string, schemeId: string, config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<DetailsPolicyIndexResponse>(`/api/v1/policies/${schemeId}/${policyNumber}`, config);

type GetPolicyListSearchParams = RequestSearchParamsAdapter & {
  schemeId: string;
  policyNumbers?: string[];
  poNumbers?: string[];
  fullName?: string;
  niNumber?: string;
  doB?: string;
  commencementDate?: string;
};
export const getPolicyListSearch = (params: GetPolicyListSearchParams, config: AxiosRequestConfig = {}) =>
  eArchiveApi.get<SearchPolicyIndexResponse>('/api/v1/policies/search', { ...config, params });
