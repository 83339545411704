export { ResultFailType } from '@itm/shared-frontend';
export type {
  TableSortParams,
  TableChangeParams,
  SelectOption,
  ServerValidationError,
  ServerValidationResponse,
  ServerError,
  FieldErrors,
  ServerFormErrors,
  // Data Access Permission
  ErrorWithType,
  SchemeFromProductDto,
  ProductDto,
  CreateDataAccessDto,
  ShortSchemeResponse,
  ProductSchemeResponse,
} from '@itm/shared-frontend/lib/types';

export enum SearchOrder {
  asc,
  desc,
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'eArchive_Analyst',
  Viewer = 'eArchive_Viewer',
  ClientAdmin = 'eArchive_ClientAdmin',
  Manager = 'eArchive_Manager',
}

export type CompanyResponse = {
  id: string;
  name: string;
};

export type SchemeResponse = {
  id: string;
  name: string;
  companyId: string;
};

export type PolicyIndexResponse = {
  policyNumber: string;
  poNumber: string | null;
  fullName: string | null;
  niNumber: string | null;
  clientRoleType: string | null;
  agentId: string | null;
  product: string | null;
  dateOfBirth: string | null;
  commencementDate: string | null;
};

export type SearchPolicyIndexResponse = {
  totalCount: number;
  policies: PolicyIndexResponse[];
};

export type DetailsPolicyIndexResponse = {
  value: PolicyIndexResponse;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export enum ReportName {
  PolicyDetails = 'Policy details',
  Transactions = 'Transactions',
  Comments = 'Comments',
  SumAssured = 'Sum assured',
  Ratings = 'Ratings',
}

export type Report = {
  id: string;
  name: ReportName;
};

export type DownloadReportDto = {
  schemeId: string;
  reportId: string;
  policyNumbers?: string[];
  productTypes?: string[];
  mainClasses?: string[];
  startDate?: string;
  endDate?: string;
};

export type AuditResponse = {
  id: string;
  userName: string;
  userId: string;
  eventType: string;
  entityType: string;
  dateTime: string;
  oldValues: string;
  newValues: string;
  affectedColumn: string;
  entityId: string;
};

export type GetAuditResponse = {
  totalCount: number;
  auditRecords: AuditResponse[];
};

export enum UserAuditType {
  NotSupported = 'NotSupported',
  Search = 'Search',
  Download = 'Download',
}

export enum UserAuditStatus {
  NotFound = 'NotFound',
  Ok = 'Ok',
  BadRequest = 'BadRequest',
}

export type UserAuditResponse = {
  id: string;
  params: string | null;
  userId: string;
  userName: string | null;
  elapsed: string;
  type: UserAuditType;
  status: UserAuditStatus;
  dateTime: string;
};

export type UserAuditRecordsResponse = {
  totalCount: number;
  auditRecords: UserAuditResponse[];
};

export type GetLogListSearchParams = {
  take: string;
  search?: string;
  logLevel?: keyof typeof LogLevel;
  startDateTime?: string;
  endDateTime?: string;
};

export type LogResponse = {
  timestamp: string | null;
  level: string | null;
  traceId: string | null;
  message: string | null;
  exception: string | null;
};

export type LogRecordsResponse = {
  totalCount: number;
  logRecords: LogResponse[];
};

export enum LogLevel {
  All = 'All',
  Warning = 'Warning',
  Error = 'Error',
}
