import axios from 'axios';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { selectedSchemeIdSelector } from 'src/store/selectors/schemeSelector';

import { Loading, FormatDate } from '@itm/shared-frontend/lib/components';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import { getPolicyByPolicyNumber } from 'src/api/eArchive/policy';

import { PolicyIndexResponse, ServerError, ServerFormErrors } from 'src/types';

type RouteParams = {
  policyNumber: string;
};

function PolicyDetails() {
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);

  const { policyNumber } = useParams<RouteParams>();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [policyDetails, setPolicyDetails] = useState<Partial<PolicyIndexResponse>>({});
  const [globalServerErrorMessages, setGlobalServerErrorMessages] = useState<ServerFormErrors>([]);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const fillPolicyDetailsData = useCallback(async () => {
    if (!policyNumber || !selectedSchemeId) return;
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setGlobalServerErrorMessages([]);
    try {
      const res = await getPolicyByPolicyNumber(policyNumber, selectedSchemeId, {
        signal: abortController.signal,
      });
      setPolicyDetails(res.data.value);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      const { formErrors } = new ServerErrorAdapter(e as ServerError);
      setGlobalServerErrorMessages(formErrors);
    }
    abortControllerSet.delete(abortController);
  }, [policyNumber, selectedSchemeId, abortControllerSet]);

  const init = useCallback(async () => {
    setIsPageLoading(true);
    await fillPolicyDetailsData();
    setIsPageLoading(false);
  }, [fillPolicyDetailsData]);

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => () => cleanup(), [cleanup]);

  return (
    <main className="is-flex-grow-1 pt-4">
      <div className="container">
        <div className="mb-5">
          <Link to={{ pathname: RoutePath.landingPolicySearch }} state={{ from: RoutePath.landingPolicyDetails }}>
            Back to Search
          </Link>
        </div>
        <Loading isLoading={isPageLoading}>
          <h2 className="title">{policyDetails.fullName}</h2>
          <div className="card">
            <div className="has-text-weight-semibold is-size-3 mb-4">Policy details</div>
            <div className="table-container">
              <table className="table is-striped is-fullwidth">
                <tbody>
                  <tr>
                    <td className="is-narrow is-nowrap has-text-weight-semibold">Role type</td>
                    <td>{policyDetails.clientRoleType || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow is-nowrap has-text-weight-semibold">Policy Number</td>
                    <td>{policyDetails.policyNumber || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow is-nowrap has-text-weight-semibold">PO Number</td>
                    <td>{policyDetails.poNumber || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow is-nowrap has-text-weight-semibold">Commencement Date</td>
                    <td>
                      {policyDetails.commencementDate ? <FormatDate date={policyDetails.commencementDate} /> : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <td className="is-narrow is-nowrap has-text-weight-semibold">Product</td>
                    <td>{policyDetails.product || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow is-nowrap has-text-weight-semibold">Agent ID</td>
                    <td>{policyDetails.agentId || 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Loading>
        <ServerErrorMessages messages={globalServerErrorMessages} />
      </div>
    </main>
  );
}

export default PolicyDetails;
