import qs from 'qs';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';

import { clientPortalLoginUrl } from '@itm/shared-frontend/lib/utils';

export default function LoginRedirect() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { state } = useLocation();
  const redirectTo = Object(state).href || window.location.origin;

  useEffect(() => {
    if (isLoggedIn) return;
    const href = `${clientPortalLoginUrl}?${qs.stringify({ redirectTo })}`;
    window.location.href = href;
  }, [isLoggedIn, redirectTo]);

  return isLoggedIn ? <Navigate to={RoutePath.root} /> : <></>;
}
