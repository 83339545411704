export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Landing
  landingRoot: '/landing',
  landingRootConsole: '/landing/console',

  // Landing tabs
  landingPolicySearch: '/landing/console/policy/search',
  landingReports: '/landing/console/reports',
  landingUserAudit: '/landing/console/user-audit',
  landingAudit: '/landing/console/audit',
  landingLogs: '/landing/console/logs',
  landingProductInfo: '/landing/console/info',

  // Policy
  landingPolicyDetails: '/landing/policy/:policyNumber/details',
};

export { GuardedRoute } from './GuardedRoute';
