import axios from 'axios';
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Column, CellProps } from 'react-table';
import { generatePath, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { selectedSchemeIdSelector } from 'src/store/selectors/schemeSelector';

import { Table, TextareaArrayColumnFilter, DateColumnFilter, FormatDate } from '@itm/shared-frontend/lib/components';
import { ViewButton } from '@itm/shared-frontend/lib/components/buttons';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter, RequestSearchParamsAdapter } from '@itm/shared-frontend/lib/utils/';

import { DataAccessPermission, isDataAccessError } from '@itm/shared-frontend/lib/components/dataAccess';
import useDataAccessPermission from 'src/hooks/useDataAccessPermission';

import { clientPortalApi } from 'src/api';
import { getPolicyListSearch } from 'src/api/eArchive/policy';

import { TableChangeParams, PolicyIndexResponse, ServerFormErrors } from 'src/types';

type PrevExternalTableFilters = { selectedSchemeId: string };

const orderFieldMap: Partial<Record<keyof PolicyIndexResponse, string>> = {
  policyNumber: 'PolicyNumbers',
  poNumber: 'PONumbers',
  fullName: 'FullName',
  niNumber: 'NiNumber',
  dateOfBirth: 'DoB',
  commencementDate: 'CommencementDate',
};

function PolicySearch() {
  const { state: locationState } = useLocation();
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);
  const [data, setData] = useState<PolicyIndexResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);

  const prevExternalFilters = useRef<PrevExternalTableFilters>({ selectedSchemeId });
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);
  const hasSavedState = useMemo(() => Object(locationState).from === RoutePath.landingPolicyDetails, [locationState]);

  const {
    companyId,
    companyName,
    productId,
    hasDataAccessToScheme,
    hasDataAccess,
    setHasDataAccess,
    setIsShowDataAccessForm,
    requestSchemeList,
    resetDataAccessState,
  } = useDataAccessPermission();

  const columns = useMemo<Column<PolicyIndexResponse>[]>(
    () => [
      { Header: 'Policy Number', accessor: 'policyNumber', Filter: TextareaArrayColumnFilter },
      { Header: 'PO Number', accessor: 'poNumber', Filter: TextareaArrayColumnFilter },
      { Header: 'Name', accessor: 'fullName' },
      { Header: 'Ni Number', accessor: 'niNumber' },
      {
        Header: 'Date Of Birth',
        accessor: 'dateOfBirth',
        className: 'is-nowrap',
        Filter: DateColumnFilter,
        Cell: ({ value }) => (value ? <FormatDate date={value} /> : <></>),
      },
      {
        Header: 'Commencement Date',
        accessor: 'commencementDate',
        className: 'is-nowrap',
        Filter: DateColumnFilter,
        Cell: ({ value }) => (value ? <FormatDate date={value} /> : <></>),
      },
      {
        Header: () => null,
        id: 'action',
        disableSortBy: true,
        headerClassName: 'is-narrow',
        Cell: ({ row }: React.PropsWithChildren<CellProps<PolicyIndexResponse, number>>) => {
          const policy = row.original;
          const routePath = generatePath(RoutePath.landingPolicyDetails, { policyNumber: policy.policyNumber });

          return (
            <div className="field is-grouped">
              <ViewButton aria-label={`View policy ${policy.policyNumber} details`} to={routePath} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  const tableChangeHandler = useCallback(
    async (tableChangeParams: TableChangeParams<PolicyIndexResponse>) => {
      cleanup();
      setServerErrorMessages([]);
      if (!selectedSchemeId) {
        setData([]);
        setIsLoading(false);
        return;
      }
      if (prevExternalFilters.current.selectedSchemeId !== selectedSchemeId) {
        setData([]);
      }
      const abortController = new AbortController();
      abortControllerSet.add(abortController);
      prevExternalFilters.current = { selectedSchemeId };
      setIsLoading(true);
      try {
        const filters: Partial<Record<keyof PolicyIndexResponse, any>> = tableChangeParams.filters.reduce(
          (acc, { id, value }) => ({ ...acc, [id]: value }),
          {},
        );
        const params = {
          ...new RequestSearchParamsAdapter(tableChangeParams),
          schemeId: selectedSchemeId,
          policyNumbers: filters.policyNumber,
          poNumbers: filters.poNumber,
          fullName: filters.fullName,
          niNumber: filters.niNumber,
          doB: filters.dateOfBirth,
          commencementDate: filters.commencementDate,
          orderField: tableChangeParams.sortedByField
            ? orderFieldMap[tableChangeParams.sortedByField as keyof PolicyIndexResponse] ||
              tableChangeParams.sortedByField
            : tableChangeParams.sortedByField,
        };
        const config = { signal: abortController.signal };
        const res = await getPolicyListSearch(params, config);
        const { totalCount, policies } = res.data;
        setPageCount(Math.ceil(totalCount / tableChangeParams.pageSize));
        setHasDataAccess(true);
        setData(policies);
      } catch (e) {
        if (e instanceof axios.Cancel || !(e instanceof axios.AxiosError)) return;

        if (isDataAccessError(e.response)) {
          setHasDataAccess(false);
          setPageCount(0);
          setData([]);
        } else {
          const serverErrors = new ServerErrorAdapter(e);
          setServerErrorMessages(serverErrors.combine());
        }
      } finally {
        abortControllerSet.delete(abortController);
        setIsLoading(false);
      }
    },
    [cleanup, selectedSchemeId, abortControllerSet, setHasDataAccess],
  );

  useEffect(() => {
    setServerErrorMessages([]);
  }, [companyId]);

  useEffect(() => () => cleanup(), [cleanup]);

  const SuccessDataAccessButtonComponent = useMemo(
    () => () => {
      const clickHandler = async () => {
        await requestSchemeList();
        resetDataAccessState();
      };
      return (
        <button className="button is-interact" type="button" onClick={clickHandler}>
          Back to Search
        </button>
      );
    },
    [requestSchemeList, resetDataAccessState],
  );

  return (
    <>
      <ServerErrorMessages className="mb-4" messages={serverErrorMessages} />
      {!hasDataAccessToScheme || !hasDataAccess ? (
        <DataAccessPermission
          companyId={companyId}
          companyName={companyName}
          clientPortalApiInstance={clientPortalApi}
          productId={productId}
          messageButtonOnClick={() => setIsShowDataAccessForm(true)}
          SuccessButtonComponent={SuccessDataAccessButtonComponent}
        />
      ) : (
        <Table
          name="policySearchTable"
          uniqueKey="policyNumber"
          key={selectedSchemeId}
          data={data}
          columns={columns}
          pageCount={pageCount}
          isLoading={isLoading}
          hasSavedState={hasSavedState}
          onParamsChange={tableChangeHandler}
          disableGlobalFilter
        />
      )}
    </>
  );
}

export default PolicySearch;
