import qs from 'qs';
import { createBrowserHistory, History, To, Path } from 'history';
import { preserveKeyList } from 'src/hocs/SyncFiltersWithURL';

type CreateHistory<O, H> = (options?: O) => History & H;

function preserveQueryParameters(history: History, preserveKeyList: string[], location: Partial<Path>): Partial<Path> {
  const currentQuery = qs.parse(history.location.search.slice(1));
  if (currentQuery) {
    const preservedQuery: Record<string, unknown> = preserveKeyList.reduce(
      (acc, preserveKey) => (currentQuery[preserveKey] ? { ...acc, [preserveKey]: currentQuery[preserveKey] } : acc),
      {},
    );
    if (location.search) {
      const nextRouteQuery = qs.parse(location.search.slice(1));
      Object.assign(preservedQuery, nextRouteQuery);
    }
    location.search = qs.stringify(preservedQuery);
  }
  return location;
}

function createLocationDescriptorObject(to: To): Partial<Path> {
  return typeof to === 'string' ? { pathname: to } : to;
}

export function createPreserveQueryHistory<O, H>(
  createHistory: CreateHistory<O, H>,
  queryParameters: string[],
): CreateHistory<O, H> {
  return (options?: O) => {
    const history = createHistory(options);
    const oldPush = history.push;
    const oldReplace = history.replace;
    history.push = (to: To, state?: any) =>
      oldPush.apply(history, [
        preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(to)),
        state,
      ]);
    history.replace = (to: To, state?: any) =>
      oldReplace.apply(history, [
        preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(to)),
        state,
      ]);
    return history;
  };
}

export const history = createPreserveQueryHistory(createBrowserHistory, [...preserveKeyList])();
