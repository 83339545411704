import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'src/store';
import { productIdSelector } from 'src/store/selectors/productSelector';
import { selectedCompanyIdSelector, selectedCompanyNameSelector } from 'src/store/selectors/companySelector';
import { hasDataAccessToSchemeSelector } from 'src/store/selectors/schemeSelector';
import { fetchSchemeListByCompanyAction } from 'src/store/slices/schemeSlice';

const useDataAccessPermission = () => {
  const dispatch = useDispatch();
  const productId = useSelector(productIdSelector);
  const companyId = useSelector(selectedCompanyIdSelector);
  const companyName = useSelector(selectedCompanyNameSelector);
  const hasDataAccessToScheme = useSelector(hasDataAccessToSchemeSelector);
  const [hasDataAccess, setHasDataAccess] = useState(true);
  const [isShowDataAccessForm, setIsShowDataAccessForm] = useState(false);

  const requestSchemeList = useCallback(async () => {
    if (!companyId) return;
    await dispatch(fetchSchemeListByCompanyAction(companyId));
  }, [companyId, dispatch]);

  const resetDataAccessState = () => {
    setHasDataAccess(true);
    setIsShowDataAccessForm(false);
  };

  useEffect(() => {
    resetDataAccessState();
  }, [companyId]);

  return {
    companyId,
    companyName,
    productId,
    hasDataAccessToScheme,
    hasDataAccess,
    isShowDataAccessForm,
    setHasDataAccess,
    setIsShowDataAccessForm,
    requestSchemeList,
    resetDataAccessState,
  };
};

export default useDataAccessPermission;
