import { string, addMethod, StringSchema } from 'yup';

addMethod<StringSchema>(string, 'maxLines', function (maxLines: number) {
  return this.test(
    'maxLines',
    ({ label }) => `max ${maxLines} ${label} is allowed`,
    (value?: string) => {
      if (!value) return true;
      return value.trim().split(/[\s\b]+/g).length <= maxLines;
    },
  );
});
