import { Converter } from '@itm/shared-frontend/lib/utils';
import getEnvVariable from './getEnvVariable';

import { ReportName, UserAuditType, UserAuditStatus, LogLevel } from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const EARCHIVE_API_URL = getEnvVariable('EARCHIVE_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';

export const reportNameOptions = Converter.enumToSelectOptions(ReportName);
export const userAuditTypeOptions = Converter.enumToSelectOptions(UserAuditType);
export const userAuditStatusOptions = Converter.enumToSelectOptions(UserAuditStatus);
export const logLevelOptions = Converter.enumToSelectOptions(LogLevel);
